<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      query="&sort=-id&expand=trainType,userRole"
      entity-name="培训"
      show-row-num
      hide-show-detail
      hide-edit
      hide-delete
      add-btn="添加培训"
      :columns="columns"
      :search-key-type="searchKeyType"
      :add-route="addRoute"
      :actionWidth="200"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入课程名称查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择课程类型查询"
            @change="updateList"
            :options="typeOptions"
            v-model="form.train_id"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择是否考试查询"
            @change="updateList"
            :options="flagOptions"
            v-model="form.is_exam"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="showDetail(item)" v-action:query>查看</a>
          <a class="txt-btn" @click.stop="editRow(item)" v-action:update>编辑</a>
          <a class="txt-btn danger" @click.stop="delRow(item)" v-action:delete>删除</a>
          <a class="txt-btn" @click.stop="showEnroll(item)" v-if="item.form == 2">报名名单</a>
          <a class="txt-btn" @click.stop="goAttendance(item)" v-if="item.form == 2">出勤情况</a>
          <a class="txt-btn" @click.stop="showScore(item)" v-if="item.is_exam == 1">成绩管理</a>
        </div>
      </template>
    </data-list>
    <ScoreModal v-if="sVisible" v-model="sVisible" :info="course"></ScoreModal>
    <EnrollModal v-if="eVisible" v-model="eVisible" :info="course" :type="2"></EnrollModal>
  </div>
</template>

<script>
import { dataConvertOptions, getKeyTitle } from "../../../common/js/tool";
import { flagOptions, teachingFormatOptions } from "../../../common/hr/volunteer";
import ScoreModal from "./components/ScoreModal.vue";
import EnrollModal from "./components/EnrollModal.vue";

export default {
  name: "TrainList",
  components: {
    ScoreModal,
    EnrollModal,
  },
  data() {
    return {
      url: "/admin/volunteer-train-list",
      searchKeyType: {
        name: 1,
        title: 1,
      },
      columns: [
        { title: "课程编号", dataIndex: "course_num" },
        { title: "课程名称", dataIndex: "name" },
        {
          title: "课程类型",
          customRender: (text) => {
            return <span>{text.trainType?.name || "-"}</span>;
          },
        },
        {
          title: "授课形式",
          dataIndex: "form",
          customRender: (text) => {
            return <span>{getKeyTitle(teachingFormatOptions, text, "value", "label")}</span>;
          },
        },
        {
          title: "是否设置考试",
          dataIndex: "is_exam",
          customRender: (text) => {
            return <span>{getKeyTitle(flagOptions, text, "value", "label")}</span>;
          },
        },
      ],
      typeOptions: [],
      flagOptions,
      addRoute: "TrainAdd",
      course: null,
      sVisible: false,
      eVisible: false,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      return this.$axios({
        url: "/admin/volunteer-train-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.typeOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.dataList.updateList();
    },
    showDetail(item) {
      this.$router.push({
        name: "TrainDetail",
        params: { id: item.id },
      });
    },
    editRow(item) {
      this.$router.push({
        name: "TrainDetail",
        params: { id: item.id },
        query: { edit: 1 },
      });
    },
    delRow(item) {
      this.$confirm({
        title: "提示",
        content: `确定删除此课程吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${item.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
    showScore(item) {
      this.course = item;
      this.sVisible = true;
    },
    goAttendance(item) {
      this.$router.push({
        name: "AttendanceList",
        query: {
          course_id: item.id,
        },
      });
    },
    showEnroll(item) {
      this.course = item;
      this.eVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.status-1 {
  color: @error-color;
}
.status-2 {
  color: @success-color;
}
.ant-calendar-picker {
  text-align: left;
}
</style>
