<template>
  <a-modal
    v-model="visible"
    width="80%"
    :maskClosable="false"
    title="成绩管理"
    ok-text="确认"
    cancel-text="取消"
    :footer="null"
  >
    <data-list
      class="scroll-list"
      ref="dataList"
      :url="url"
      :query="query"
      :requestConfig="{ noTempleFilter: true }"
      entity-name="成绩"
      show-row-num
      hide-show-detail
      hide-delete
      :hide-action="info.form == 1"
      :columns="columns"
      :search-key-type="searchKeyType"
      :rules="rules"
    >
      <template v-slot:button>
        <a-button @click="downloadTemplate">下载模板</a-button>
        <upload-file
          uploadText="批量导入"
          type="primary"
          url="/admin/volunteer-achievement/import"
          :exrtaParams="{ train_list_id: info.id }"
          @change="updateList"
          v-if="info && info.form == 2"
        ></upload-file>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "批量导出" }}
        </a-button>
      </template>
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入志工姓名查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.id_card" placeholder="输入身份证号查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.tel" placeholder="输入手机号码查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择是否通过考试查询"
            @change="updateList"
            :options="examStatusOptions"
            v-model="form.is_pass"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </template>
      <template v-slot:edit="{ form }">
        <template v-if="form && form.volunteer">
          <a-form-model-item label="志工姓名" prop="volunteer.name">
            <a-input :value="form.volunteer.name" placeholder="请输入志工姓名" allowClear disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="身份证号" prop="volunteer.id_card">
            <a-input :value="form.volunteer.id_card" placeholder="请输入身份证号" allowClear disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="手机号码" prop="volunteer.tel">
            <a-input :value="form.volunteer.tel" placeholder="请输入手机号码" allowClear disabled></a-input>
          </a-form-model-item>
        </template>
        <a-form-model-item label="参考状态" prop="status">
          <a-radio-group v-model="form.status" disabled>
            <a-radio :value="item.value" v-for="item in examPartOptions" :key="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="是否通过考试" prop="is_pass">
          <a-radio-group v-model="form.is_pass" disabled>
            <a-radio :value="item.value" v-for="item in examStatusOptions" :key="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="考试成绩" prop="score">
          <a-input-number
            v-model="form.score"
            placeholder="请输入考试成绩"
            :min="1"
            :step="1"
            allowClear
          ></a-input-number>
        </a-form-model-item>
      </template>
    </data-list>
  </a-modal>
</template>

<script>
import { examPartOptions, examStatusOptions, scoreTemplateUrl } from "../../../../common/hr/volunteer";
import { getTemple } from "../../../../common/js/storage";
import { downLoadBlobFile, downLoadFile, getKeyTitle, getQuery, getTempleId } from "../../../../common/js/tool";

let temple = getTemple();

export default {
  name: "ScoreModal",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      value: false,
    },
    info: Object,
  },
  data() {
    return {
      url: "/admin/volunteer-achievement",
      searchKeyType: {
        name: 2,
        id_card: 2,
        tel: 2,
      },
      rules: {
        name: [{ required: true, message: "请输入志工姓名", trigger: "blur" }],
        id_card: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: "^1[3-9]\\d{9}$", message: "请输入正确的手机号码" },
        ],
        status: [{ required: true, message: "请选择参考状态", trigger: "change" }],
        is_pass: [{ required: true, message: "请选择是否通过考试", trigger: "change" }],
        score: [{ required: true, message: "请输入考试成绩", trigger: "blur" }],
      },
      examPartOptions,
      examStatusOptions,
      exporting: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    dataList() {
      return this.$refs.dataList;
    },
    columns() {
      let columns = [
        {
          title: "志工姓名",
          customRender: (text) => {
            return <span>{text.volunteer?.name || "-"}</span>;
          },
        },
        {
          title: "身份证号",
          customRender: (text) => {
            return <span>{text.volunteer?.id_card || "-"}</span>;
          },
        },
        {
          title: "手机号码",
          customRender: (text) => {
            return <span>{text.volunteer?.tel || "-"}</span>;
          },
        },
        {
          title: "参考状态",
          dataIndex: "status",
          customRender: (text) => {
            return <span>{getKeyTitle(examPartOptions, text, "value", "label")}</span>;
          },
        },
        {
          title: "是否通过考试",
          dataIndex: "is_pass",
          customRender: (text) => {
            return <span>{getKeyTitle(examStatusOptions, text, "value", "label")}</span>;
          },
        },
        { title: "考试成绩", dataIndex: "score", sorter: true },
      ];
      return columns;
    },
    query() {
      let res = `&sort=-id&expand=volunteer`;
      if (this.info) {
        res += `&filter[train_list_id]=${this.info?.id}`;
      }
      if (temple) {
        res += `&filter[volunteer_achievement.temple_id]=${temple}`;
      }
      return res;
    },
  },
  methods: {
    updateList() {
      this.dataList.updateList();
    },
    downloadTemplate() {
      downLoadFile(scoreTemplateUrl, "成绩模板");
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let searchKeyType = {
        name: 2,
        id_card: 2,
        tel: 2,
        is_pass: 2,
      };
      let url = `/admin/volunteer-achievement/export?train_list_id=${this.info.id}`;
      url += getQuery.call(this.dataList, searchKeyType);
      url += getTempleId();
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, `${this.info.name}的成绩数据`);
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-list {
  min-height: 600px;
}
</style>
